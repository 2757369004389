import React, { useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { searchOnHomes } from '../../../api/serverRequests';
import { InputAdornment } from '@material-ui/core';

function CusSelectFieldAsync({
	itemGrid,
	name,
	label,
	control,
	fieldStyle,
	multiple,
	placeholder,
	defaultValue,
	type
}) {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [loading, setLoading] = useState(open && options.length === 0);

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}
		(async () => {
			/* 			getAllHomes()
				.then((res) => {
					if (res.data.success) {
						console.log(res.data);
					}
				})
				.catch((err) => {
					console.log(err.message);
				}); */

			if (active) {
				setOptions([]);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading]);

	//Search home table given string, after user stops typing
	const setPredictionOption = (term) => {
		if (!term) return;
		if (!open) return;
		setLoading(true);

		searchOnHomes(term, true)
			.then((res) => {
				if (res.data.success) {
					console.log(res.data);
					setOptions(res.data.homes);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				setOptions([]);
			});
	};

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	//delay to prevent query flooding
	useEffect(() => {
		const timeOutId = setTimeout(() => setPredictionOption(searchValue), 1000);
		return () => clearTimeout(timeOutId);
	}, [searchValue]);

	const filterOptions = (options, value) => {
		if (options.length > 0) {
			if (options.phone) {
				return options.filter((option) => {
					return option.phone === value;
				});
			}
			if (options.mlnum) {
				return options.filter((option) => {
					return option.mlnum === value;
				});
			}
		}
		return options;
	};

	return (
		<Grid item xs={12} sm={itemGrid}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				color="primary"
				render={({ onChange, value }) => (
					<Autocomplete
						freesolo
						multiple={multiple}
						value={value}
						limitTags={5}
						style={{ width: 220 }}
						disableCloseOnSelect={multiple}
						options={options}
						open={open}
						onOpen={() => {
							setOpen(true);
						}}
						onClose={() => {
							setOpen(false);
						}}
						loading={loading}
						getOptionSelected={(option, value) => option.name === value.name}
						getOptionLabel={(option) => (option.value ? option.value : '')}
						filterOptions={(options, value) => filterOptions(options, value)}
						renderInput={(params) => (
							<>
								<TextField
									{...params}
									variant="standard"
									type={type}
									label={label}
									placeholder={placeholder}
									onChange={(e) => setSearchValue(e.target.value)}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loading ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										)
									}}
								/>
							</>
						)}
						onChange={(_, data) => {
							onChange(data);
						}}
					/>
				)}
			/>
		</Grid>
	);
}

export default React.memo(CusSelectFieldAsync);
