import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
	Box,
	FormControl,
	MenuItem,
	Select,
	InputLabel,
	Snackbar,
	CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseLayout, Lists, Tag } from '../..';
import { useUserState } from '../../../hook/customerHook';
import {
	getUserTaskBoard,
	getActiveAgents,
	getOrganizationAssignedStatusId,
	checkUserHasPermissionId,
	getAllTaskStatusForUser,
	getAllPermissionForUser
} from '../../../api/serverRequests';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { PERMS } from '../../../helper/perms';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	fixedHeight: {
		height: 120
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	box: {
		position: 'relative',
		alignSelf: 'flex-end',
		top: 0,
		right: 0,
		padding: 1,
		backgroundColor: 'white',
		boxShadow: 2,
		border: '1px solid rgba(0, 0, 0, 0.12)',
		borderRadius: 1
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	select: { minWidth: 150 },
	selectProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const VIEW_ALL_BOARDS_PERM_ID = PERMS.VIEW_ALL_BOARDS;

export default function MyTasks() {
	const classes = useStyles();
	const [agentsLoading, setAgentsLoading] = useState(true);
	const [boardLoading, setBoardLoading] = useState(true);
	const [userState, dispatchUser] = useUserState();
	const [curAgent, setCurAgent] = useState(userState.user.user_id);
	const [curAgentName, setCurAgentName] = useState(userState.user.name);
	const [agents, setAgents] = useState([]);
	const [viewAllBoardsPermission, setViewAllBoardsPermission] = useState(false);

	// // submit status updated after user has clicked save in edit modal
	const [submitSuccessStatus, setSubmitSuccessStatus] = useState(''); // keeps track of status when data is posted

	// for notification after user has clicked save in edit modal
	const [notificationState, setNotificationState] = useState(null); // used to open/close the notificaiton
	const [notificationMessage, setNotificationMessage] = useState(''); // used to set error message

	const [listings, setListings] = useState();
	const [selectedStatus, setSelectedStatus] = useState();
	const [statusList, setStatusList] = useState([]);

	// permission for assigning tasks
	const [editAssignedTaskVisible, setEditAssignedTaskVisible] = useState(false);
	// permission for assign task only for myself
	const [assignMyself, setAssignMyself] = useState(false);

	const handleDropdownChange = (event) => {
		const text = event.target.innerText;
		setCurAgent(event.target.value);
		setCurAgentName(text);
		setBoardLoading(true);
		setListings([]);
		setSelectedStatus();
	};

	useEffect(() => {
		getAllPermissionForUser(userState.user.user_id).then((res) => {
			if (res.data.success) {
				const permissions = res.data.permissions;
				permissions.forEach((permission) => {
					if (PERMS.EDIT_ASSIGN_TASKS === permission.PermissionId) {
						setEditAssignedTaskVisible(true);
					}
					if (PERMS.ASSIGN_TASK_MYSELF === permission.PermissionId) {
						setAssignMyself(true);
					}
					if (PERMS.VIEW_ALL_BOARDS === permission.PermissionId) {
						setViewAllBoardsPermission(true);
					}
				});
			}
		});
	}, []);

	useEffect(() => {
		const fetchData = () => {
			return getActiveAgents()
				.then((res) => {
					if (res.status === 200) {
						setAgents(res.data.agents);
						setAgentsLoading(false);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage('Error fetching agents');
					}
				})
				.catch((err) => {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Error fetching agents');
				});
		};

		const fetchAssignedStatus = () => {
			return getOrganizationAssignedStatusId()
				.then((res) => {
					if (res.data.success) {
						localStorage.setItem(
							'assignedStatusId',
							JSON.stringify(res.data.assignedStatusId)
						);
					} else {
						setSubmitSuccessStatus('error');
						setNotificationState(true);
						setNotificationMessage('Error fetching assigned status id');
					}
				})
				.catch((err) => {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Error fetching assigned status id');
				});
		};

		if (!localStorage.getItem('assignedStatusId')) {
			fetchAssignedStatus();
		}

		if (agentsLoading) {
			fetchData();
		}
	}, []);

	useEffect(() => {
		const fetchData = () => {
			return getAllTaskStatusForUser(curAgent, '', moment().toISOString())
				.then((res) => {
					if (res.status === 200) {
						setStatusList(res.data.task_data);
						setBoardLoading(false);
					}
				})
				.catch((err) => {
					setSubmitSuccessStatus('error');
					setNotificationState(true);
					setNotificationMessage('Error loading tasks');
					console.log(err.message);
				});
		};

		if (boardLoading) {
			fetchData();
		}
	}, [boardLoading]);

	const updateStatusList = () => {
		getAllTaskStatusForUser(curAgent, '', moment().toISOString())
			.then((res) => {
				if (res.status === 200) {
					setStatusList(res.data.task_data);
					console.log('Updated status list:', res.data.task_data);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				setNotificationState(true);
				setNotificationMessage('Error updating status list');
				console.log(err.message);
			});
	};

	const titles = [
		{ label: 'Image', numeric: false, id: 'Pics' },
		//	{ label: '', numeric: false, id: 'ML #' },
		{ label: 'Knockability', numeric: true, id: 'Knockability' },
		{ label: 'Address', numeric: false, id: 'Address' },
		{ label: 'Area', numeric: false, id: 'Area' },
		{ label: 'SubArea', numeric: false, id: 'S/A' },
		{ label: 'Price', numeric: true, id: 'Price' },
		{ label: 'Assessment', numeric: true, id: 'assessment' },
		{ label: 'Total Bedrooms', numeric: true, id: 'Tot BR' },
		{ label: 'Total Bathrooms', numeric: true, id: 'Tot Baths' },
		{ label: 'Kitchens', numeric: true, id: '#Kitchens' },
		{ label: 'Total Floor Area', numeric: true, id: 'TotFlArea' },
		{ label: 'Year Built', numeric: true, id: 'Yr Blt' },
		{ label: 'Frontage (Feet)', numeric: true, id: 'Frontage - Feet' },
		{ label: 'Lot Size', numeric: true, id: 'Lot Sz (Sq.Ft.)' },
		{ label: 'Assigned At', numeric: false, id: 'AssignedAt' }
		//{label: 'Direction',numeric: false, id:''},
	];

	const cells = [
		//	'MLNum',
		'Knockability',
		'Address',
		'Area',
		'SA',
		'Price',
		'assessment',
		'TotBR',
		'TotBaths',
		'NumKitchens',
		'TotFlArea',
		'YrBlt',
		'FrontageFeet',
		'LotSzSqFt',
		'AssignedAt'
	];
	const [selListings, setSelListings] = useState();

	const handleSelect = (selectedlistings, on) => {
		if (on === false) {
			let tempListings = [];
			for (let i = 0; i < listings.length; i++) {
				for (let j = 0; j < selectedlistings.length; j++) {
					if (
						`${listings[i]['MLNum']}__${listings[i]['HomeId']}` === selectedlistings[j]
					) {
						tempListings.push(listings[i]);
					}
				}
			}

			setSelListings(tempListings);
		} else {
			setSelListings();
		}
	};

	const handleStatusSelect = (statusId) => {
		getUserTaskBoard(curAgent, statusId)
			.then((res) => {
				if (res.status === 200) {
					setListings(res.data.listings);
					setBoardLoading(false);
				}
			})
			.catch((err) => {
				setSubmitSuccessStatus('error');
				setNotificationState(true);
				setNotificationMessage('Error loading tasks');
				console.log(err.message);
			});
	};

	return (
		<BaseLayout>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={notificationState}
				autoHideDuration={6000}
				onClose={() => setNotificationState(false)}
			>
				<Alert
					onClose={() => setNotificationState(false)}
					severity={submitSuccessStatus === 'success' ? 'success' : 'error'}
					variant="filled"
				>
					{notificationMessage}
				</Alert>
			</Snackbar>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Box item>
							<Grid
								container
								wrap="nowrap"
								spacing={3}
								className={classes.horizontalTag}
							>
								{statusList.map((status, key) => (
									<Grid item>
										<Tag
											key={status.StatusId}
											tag={status.StatusName}
											count={status.Total}
											handleClick={() => {
												if (status.Total > 0) {
													handleStatusSelect(status.StatusId);
												}
												setSelectedStatus(status.StatusId);
											}}
											isSelected={status.StatusId === selectedStatus}
										/>
									</Grid>
								))}
							</Grid>
						</Box>
						{viewAllBoardsPermission && (
							<Box className={classes.wrapper}>
								<FormControl variant="outlined">
									<InputLabel htmlFor="agent-dropdown">Select Agent</InputLabel>
									<Select
										onChange={handleDropdownChange}
										label="Select Agent"
										className={classes.select}
										value={curAgent}
									>
										{agents.map((agent) => (
											<MenuItem key={agent.uuid} value={agent.uuid}>
												{agent.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								{agentsLoading && (
									<CircularProgress
										size={24}
										className={classes.selectProgress}
									/>
								)}
							</Box>
						)}
					</Paper>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							{listings && listings.length > 0 && (
								<Lists
									isSearch={true}
									isCollection={false}
									listHeads={titles}
									listCells={cells}
									listings={selListings ? selListings : listings}
									setListings={setListings}
									handleSelect={handleSelect}
									initialOrderBy={'AssignedAt'}
									setIsLoading={setBoardLoading}
									isTaskBoard={true}
									editAssignedTaskVisible={editAssignedTaskVisible}
									assignMyself={assignMyself}
									selectedStatus={selectedStatus}
									selectedAgent={curAgent}
									updateStatusList={updateStatusList}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</BaseLayout>
	);
}
