import React, { useState, useRef, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Typography,
	Card,
	CardContent,
	Grid,
	Avatar,
	CircularProgress,
	Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	Timeline,
	TimelineItem,
	TimelineContent,
	TimelineSeparator,
	TimelineConnector
} from '@material-ui/lab';
import {
	GoogleMap,
	DirectionsService,
	DirectionsRenderer,
	Marker,
	LoadScript
} from '@react-google-maps/api';
import HomeIcon from '@material-ui/icons/Home';
import FlagIcon from '@material-ui/icons/Flag';
import MapIcon from '@material-ui/icons/Map';
import clsx from 'clsx';
import { imageResolver } from '../../../helper/image-resolver';
import { currencyParser, formatAddress } from '../../../helper/parser';
import { useParams } from 'react-router-dom';
import { getSavedRouteDetails, fetchUserDetails } from '../../../api/serverRequests';
import { MAP } from '../../../helper/api';
import DefaultImage from '../../../assets/images/lead_logos/default.png';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		padding: theme.spacing(3),
		maxWidth: '1200px',
		margin: '0 auto',
		background: '#f5f7fa',
		minHeight: '100vh',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2)
		}
	},
	pageHeader: {
		marginBottom: theme.spacing(3),
		color: '#1a237e',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2),
			fontSize: '1.75rem'
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: '-8px',
			left: 0,
			width: '60px',
			height: '4px',
			backgroundColor: '#28A96E'
		}
	},
	dialogContent: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1)
		}
	},
	timelineWrapper: {
		margin: 0,
		padding: theme.spacing(2),
		backgroundColor: '#fff',
		borderRadius: theme.shape.borderRadius,
		boxShadow: '0 2px 15px rgba(0,0,0,0.05)',
		transition: 'all 0.3s ease',
		'&:hover': {
			transform: 'translateY(-2px)',
			boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
			background: '#28A96E0A'
		}
	},
	timelineItem: {
		margin: 0,
		'&.MuiTimelineItem-missingOppositeContent': {
			margin: 0,
			'&:before': {
				content: "''",
				flex: 'none',
				padding: 0
			}
		},
		'& .MuiTimelineSeparator-root': {
			alignItems: 'start'
		},
		'& MuiTimelineContent-root': {
			padding: theme.spacing(0.7, 0.5, 0.5, 0)
		},
		'& .MuiTimelineContent-root': {
			padding: '0 0 5px 5px'
		},
		'& .MuiTimelineConnector-root': {
			backgroundColor: '#28A96E',
			height: '30%'
		},
		'& .MuiTimelineConnector-root:nth-child(1)': {
			backgroundColor: '#28A96E',
			height: '60%'
		}
	},
	firstItem: {
		'& .MuiTimelineConnector-root:nth-child(1)': {
			backgroundColor: 'white',
			height: '60%'
		}
	},
	lastItem: {
		'& .MuiTimelineConnector-root:last-child': {
			backgroundColor: 'white !important'
		}
	},
	cardRoot: {
		flexGrow: 1,
		width: '100%',
		border: 'none',
		background: 'unset'
	},
	contentContainer: {
		padding: '0 !important',
		backgroundColor: 'unset'
	},
	topRow: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexFlow: 'row nowrap',
		width: '100%'
	},
	avatarAddress: {
		alignItems: 'center',
		display: 'flex',
		flexFlow: 'row nowrap',
		flexGrow: '1',
		gap: theme.spacing(1.5)
	},
	listingAddress: {
		'& a': {
			color: '#1a237e',
			textDecoration: 'none',
			fontWeight: 500,
			'&:hover': {
				color: '#28A96E',
				textDecoration: 'underline'
			}
		}
	},
	priceLine: {
		gap: theme.spacing(1)
	},
	priceText: {
		fontWeight: 700,
		color: '#28A96E',
		fontSize: '1.1rem',
		marginRight: '5px'
	},
	startStopSeparator: {
		fontSize: '16px',
		fontWeight: 600,
		color: 'black'
	},
	timelineSeparatorNumber: {
		width: '20px',
		height: '20px',
		borderRadius: '50%',
		background: '#28A96E26',
		marginLeft: '-10px',
		textAlign: 'center',
		'& p': {
			margin: 0,
			fontSize: '15px',
			color: '#28A96E'
		}
	},
	detailsContainer: {
		marginTop: theme.spacing(2),
		paddingTop: theme.spacing(2),
		borderTop: '1px solid rgba(0,0,0,0.08)',
		background: '#fafafa',
		borderRadius: '8px',
		padding: theme.spacing(2)
	},
	detailsGrid: {
		display: 'grid',
		gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
		gap: theme.spacing(1),
		marginTop: theme.spacing(1)
	},
	detailItem: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4px',
		padding: theme.spacing(1),
		background: '#fff',
		borderRadius: '6px',
		boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
	},
	detailLabel: {
		color: '#666666',
		fontSize: '0.875rem'
	},
	detailValue: {
		fontWeight: 500,
		fontSize: '0.875rem'
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '400px'
	},
	errorContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '400px',
		color: theme.palette.error.main
	},
	mapContainer: {
		borderRadius: '12px',
		overflow: 'hidden',
		boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
		margin: `${theme.spacing(3)}px 0`,
		height: '400px',
		[theme.breakpoints.down('sm')]: {
			height: '300px',
			margin: `${theme.spacing(2)}px 0`,
			borderRadius: '8px'
		}
	},
	heroImageContainer: {
		width: '100%',
		height: '200px',
		overflow: 'hidden',
		borderRadius: '12px',
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			height: '150px',
			marginBottom: theme.spacing(2)
		}
	},
	heroImage: {
		width: '100%',
		height: '100%',
		borderRadius: '5px',
		objectFit: 'contain',
		objectPosition: 'center',
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0
		}
	},
	mapLinkIcon: {
		color: '#1a73e8', // Google Maps blue color
		cursor: 'pointer',
		marginLeft: theme.spacing(1),
		'&:hover': {
			color: '#174ea6'
		},
		[theme.breakpoints.down('sm')]: {
			// Slightly smaller on mobile
			fontSize: '1.2rem'
		}
	},
	addressContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5)
	}
}));

const ExportRoute = () => {
	const { routeId } = useParams();
	const classes = useStyles();
	const [routeData, setRouteData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [directions, setDirections] = useState(null);
	const mapRef = useRef(null);
	const [center, setCenter] = useState({ lat: 0, lng: 0 });
	let count = useRef(0);

	// Add state to track if data is ready
	const [isDataReady, setIsDataReady] = useState(false);

	// Add heroImage state
	const [heroImage, setHeroImage] = useState(null);

	useEffect(() => {
		const fetchRouteData = async () => {
			setLoading(true);
			setIsDataReady(false);
			try {
				const response = await getSavedRouteDetails(routeId);
				if (response.data.success) {
					const route = response.data.routes[0];
					console.log('route: ', route);
					// Fetch user details for hero image
					if (route.User_id) {
						try {
							const userResponse = await fetchUserDetails(
								route.Organization_id,
								route.User_id
							);
							console.log('userResponse: ', userResponse);
							if (userResponse.data.success) {
								const userDetails = userResponse.data.user_details;
								// Assuming hero_image is the property name
								if (userDetails.logo) {
									const heroPath = require(`../../../assets/images/lead_logos/${userDetails.logo}`);
									console.log('heroPath: ', heroPath);
									setHeroImage(heroPath);
								}
							}
						} catch (err) {
							console.error('Error fetching user details:', err);
						}
					}

					setRouteData(route);

					if (route.waypoints && route.waypoints.length > 0) {
						setCenter({
							lat: route.waypoints[0][0],
							lng: route.waypoints[0][1]
						});
					}

					setIsDataReady(true);
				} else {
					throw new Error('Failed to fetch route data');
				}
			} catch (err) {
				console.error('Error fetching route data:', err);
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchRouteData();
	}, [routeId]);

	if (loading || !isDataReady) {
		return (
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
		);
	}

	if (error) {
		return (
			<div className={classes.errorContainer}>
				<Typography variant="h6">Error loading route: {error}</Typography>
			</div>
		);
	}

	if (!routeData) {
		return (
			<div className={classes.errorContainer}>
				<Typography variant="h6">Route not found</Typography>
			</div>
		);
	}

	const renderDirections = (res) => {
		if (res !== null && count.current < 2) {
			if (res.status === 'OK') {
				count.current += 1;
				setDirections(res);
			} else {
				count.current = 0;
				console.log('directions response: ', res);
			}
		}
	};

	const getMarkerIcon = (index, lastIndex) => {
		if (!window.google) return null;

		if (index === 0) {
			return {
				path: 'M 12 2 A 1 1 0 0 0 11.289062 2.296875 L 1.203125 11.097656 A 0.5 0.5 0 0 0 1 11.5 A 0.5 0.5 0 0 0 1.5 12 L 4 12 L 4 20 C 4 20.552 4.448 21 5 21 L 9 21 C 9.552 21 10 20.552 10 20 L 10 14 L 14 14 L 14 20 C 14 20.552 14.448 21 15 21 L 19 21 C 19.552 21 20 20.552 20 20 L 20 12 L 22.5 12 A 0.5 0.5 0 0 0 23 11.5 A 0.5 0.5 0 0 0 22.796875 11.097656 L 12.716797 2.3027344 A 1 1 0 0 0 12.710938 2.296875 A 1 1 0 0 0 12 2 z',
				fillColor: '#EA4335',
				fillOpacity: 1,
				size: new window.google.maps.Size(24, 24),
				anchor: new window.google.maps.Point(12, 24),
				strokeWeight: 0,
				scale: 1
			};
		} else if (index === lastIndex - 1) {
			return {
				path: 'M7.667 12H2v8H0V0h12l.333 2H20l-3 6 3 6H8l-.333-2z',
				fillColor: '#EA4335',
				fillOpacity: 1,
				size: new window.google.maps.Size(20, 20),
				anchor: new window.google.maps.Point(0, 20),
				strokeWeight: 0,
				scale: 1
			};
		} else {
			return null;
		}
	};

	const renderTimelineSeparator = (idx, max) => {
		if (idx === 0) {
			return (
				<div>
					<HomeIcon
						style={{
							marginLeft: '-10px',
							color: '#28A96E'
						}}
					/>
				</div>
			);
		} else if (idx === max - 1) {
			return (
				<div>
					<FlagIcon style={{ marginLeft: '-10px', color: '#D95140' }} />
				</div>
			);
		} else {
			return (
				<div className={classes.timelineSeparatorNumber}>
					<p>{idx}</p>
				</div>
			);
		}
	};

	const renderStartStopSeparator = (idx, max) => {
		if (idx === 0) {
			return 'Start';
		} else if (idx === max - 1) {
			return 'End';
		} else {
			return `Stop ${idx}`;
		}
	};

	const openInGoogleMaps = (address) => {
		// Format the address for the URL
		const encodedAddress = encodeURIComponent(address);

		// Check if device is mobile
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

		// Create the appropriate URL
		const mapUrl = isMobile
			? `https://maps.google.com/?q=${encodedAddress}` // Mobile URL
			: `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`; // Desktop URL

		// Open in new tab
		window.open(mapUrl, '_blank');
	};

	return (
		<div className={classes.pageContainer}>
			<Typography variant="h4" className={classes.pageHeader}>
				{routeData?.Route_name || 'Route Details'}
			</Typography>
			{/* Add Hero Image */}
			<div className={classes.heroImageContainer}>
				<img src={heroImage || DefaultImage} alt="Hero" className={classes.heroImage} />
			</div>

			<div className={classes.dialogContent}>
				{/* Map section */}
				<LoadScript googleMapsApiKey={MAP}>
					<div className={classes.mapContainer}>
						<GoogleMap
							mapContainerStyle={{ width: '100%', height: '100%' }}
							zoom={13}
							center={center}
							onLoad={(map) => {
								mapRef.current = map;
								setCenter(map.getCenter());
							}}
							options={{
								disableDefaultUI: true,
								draggable: false,
								zoomControl: false,
								scrollwheel: false,

								disableDoubleClickZoom: true,
								clickableIcons: false,
								gestureHandling: 'none'
							}}
						>
							{routeData.clusterListings.map((property, index) => {
								const point = property.location;
								const coordinates = point.match(/[-+]?\d*\.\d+|\d+/g);
								const position = {
									lat: parseFloat(coordinates[0]),
									lng: parseFloat(coordinates[1])
								};

								return (
									<Marker
										key={index}
										position={position}
										label={
											index !== 0 &&
											index !== routeData.clusterListings.length - 1
												? index.toString()
												: null
										}
										icon={getMarkerIcon(
											index,
											routeData.clusterListings.length
										)}
									/>
								);
							})}

							{routeData.clusterListings.length > 1 && (
								<DirectionsService
									options={{
										destination: {
											lat: parseFloat(
												routeData.clusterListings[
													routeData.clusterListings.length - 1
												].location.match(/[-+]?\d*\.\d+|\d+/g)[0]
											),
											lng: parseFloat(
												routeData.clusterListings[
													routeData.clusterListings.length - 1
												].location.match(/[-+]?\d*\.\d+|\d+/g)[1]
											)
										},
										origin: {
											lat: parseFloat(
												routeData.clusterListings[0].location.match(
													/[-+]?\d*\.\d+|\d+/g
												)[0]
											),
											lng: parseFloat(
												routeData.clusterListings[0].location.match(
													/[-+]?\d*\.\d+|\d+/g
												)[1]
											)
										},
										waypoints: routeData.clusterListings
											.slice(1, -1)
											.map((property) => ({
												location: {
													lat: parseFloat(
														property.location.match(
															/[-+]?\d*\.\d+|\d+/g
														)[0]
													),
													lng: parseFloat(
														property.location.match(
															/[-+]?\d*\.\d+|\d+/g
														)[1]
													)
												},
												stopover: true
											})),
										travelMode: 'DRIVING'
									}}
									callback={renderDirections}
								/>
							)}

							{directions && (
								<DirectionsRenderer
									directions={directions}
									options={{
										suppressMarkers: true
									}}
								/>
							)}
						</GoogleMap>
					</div>
				</LoadScript>
				{/* Timeline View */}
				<Timeline>
					{routeData.clusterListings.map((row, idx) => (
						<TimelineItem
							key={row.MLNum}
							className={clsx(
								classes.timelineItem,
								idx === 0 && classes.firstItem,
								idx === routeData.clusterListings.length - 1 && classes.lastItem
							)}
						>
							<TimelineSeparator>
								<TimelineConnector />
								{renderTimelineSeparator(idx, routeData.clusterListings.length)}
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								<Card className={classes.timelineWrapper}>
									<Card className={classes.cardRoot} variant="outlined">
										<CardContent className={classes.contentContainer}>
											<Typography className={classes.startStopSeparator}>
												{renderStartStopSeparator(
													idx,
													routeData.clusterListings.length
												)}
											</Typography>
											<Grid container className={classes.topRow}>
												<Grid item className={classes.avatarAddress}>
													<Avatar
														className={classes.pic}
														variant="circular"
														alt=""
														src={imageResolver(row['Pics'])}
													/>
													<Grid item>
														<Typography
															className={classes.listingAddress}
														>
															<div
																className={classes.addressContainer}
															>
																<a
																	href={`/user/listing/${row['MLNum']}`}
																	rel="noopener noreferrer"
																	onClick={(event) => {
																		event.preventDefault();
																		window.open(
																			`/user/listing/${row['MLNum']}`
																		);
																	}}
																>
																	{formatAddress(
																		`${row['Address']}, ${row['City']}`
																	)}
																</a>
																<MapIcon
																	className={classes.mapLinkIcon}
																	onClick={() =>
																		openInGoogleMaps(
																			`${row['Address']}, ${row['City']}`
																		)
																	}
																/>
															</div>
														</Typography>
														<Grid
															container
															className={classes.priceLine}
														>
															<Typography
																className={classes.priceText}
															>
																{currencyParser(row['Price'])}
															</Typography>
															<Typography>
																{row['TotBR']} bd •{' '}
																{row['TotBaths']} ba •{' '}
																{row['NumKitchens']} K •{' '}
																{row['TotFlArea']} sf
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											</Grid>

											<div className={classes.detailsContainer}>
												<div className={classes.detailsGrid}>
													{/* Property Details */}
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Year Built
														</Typography>
														<Typography className={classes.detailValue}>
															{row['YrBlt'] || 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Days on Market
														</Typography>
														<Typography className={classes.detailValue}>
															{row['DOM'] || 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															List Date
														</Typography>
														<Typography className={classes.detailValue}>
															{row['ListDate'] || 'N/A'}
														</Typography>
													</div>

													{/* Lot Details */}
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Lot Size
														</Typography>
														<Typography className={classes.detailValue}>
															{row['LotSzSqFt']
																? `${Number(
																		row['LotSzSqFt']
																  ).toLocaleString()} sq.ft.`
																: 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Frontage
														</Typography>
														<Typography className={classes.detailValue}>
															{row['FrontageFeet']
																? `${row['FrontageFeet']} ft`
																: 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Depth
														</Typography>
														<Typography className={classes.detailValue}>
															{row['Depth']
																? `${row['Depth']} ft`
																: 'N/A'}
														</Typography>
													</div>

													{/* Interior Details */}
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Total Floor Area
														</Typography>
														<Typography className={classes.detailValue}>
															{row['TotFlArea']
																? `${Number(
																		row['TotFlArea']
																  ).toLocaleString()} sq.ft.`
																: 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Finished Floor Area
														</Typography>
														<Typography className={classes.detailValue}>
															{row['FloorAreaFinTotal']
																? `${Number(
																		row['FloorAreaFinTotal']
																  ).toLocaleString()} sq.ft.`
																: 'N/A'}
														</Typography>
													</div>
													<div className={classes.detailItem}>
														<Typography className={classes.detailLabel}>
															Assessment
														</Typography>
														<Typography className={classes.detailValue}>
															{row['assessment']
																? currencyParser(row['assessment'])
																: 'N/A'}
														</Typography>
													</div>

													{/* Description */}
													<div
														className={classes.detailItem}
														style={{ gridColumn: '1 / -1' }}
													>
														<Typography className={classes.detailLabel}>
															Description
														</Typography>
														<Typography
															className={classes.detailValue}
															style={{ whiteSpace: 'pre-wrap' }}
														>
															{row['PublicRemarks'] || 'N/A'}
														</Typography>
													</div>
												</div>
											</div>
										</CardContent>
									</Card>
								</Card>
							</TimelineContent>
						</TimelineItem>
					))}
				</Timeline>
			</div>
		</div>
	);
};

export default ExportRoute;
