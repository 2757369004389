import axios from 'axios';

//const baseUrl = "http://localhost:5000";

const formDataBuilder = (formData) => {
	let bodyForm = new FormData();
	for (const [key, value] of Object.entries(formData)) {
		bodyForm.append(key, value);
	}
	return bodyForm;
};

axios.interceptors.request.use(
	function (config) {
		const location = sessionStorage.getItem('location');
		if (location) {
			config.headers.Location = location.toString();
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// This would be handled in every response returned from backends
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response && error.response.status === 401) {
			// Redirect user to login page
			window.location.href = '/login';
		}
		return Promise.reject(error);
	}
);
export const logout = () => {
	return axios({
		method: 'GET',
		url: '/api/user/logout',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const loginUser = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/index/login',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const signupUser = (formData) => {
	console.log(typeof formData);
	return axios({
		method: 'POST',
		url: '/api/index/signup',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const signupConfirm = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/index/signup-confirm',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const signupResend = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/index/resend',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const forgetPassword = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/index/forget-password',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const forgetPasswordConfirm = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/index/forget-password-confirm',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
};

export const updatePassword = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/profile',
		data: formDataBuilder(formData),
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		withCredentials: true
	});
};

export const getOrganization = () => {
	return axios({
		method: 'GET',
		url: '/api/index/organization'
		// headers: { "Content-Type": "multipart/form-data" }
	});
};

export const getProfile = () => {
	return axios({
		method: 'GET',
		url: '/api/user/profile',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getDashboard = (formData) => {
	return axios({
		method: 'GET',
		url: '/api/user/dashboard',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getUserRole = () => {
	return axios({
		method: 'GET',
		url: '/api/user/user-role',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getAreas = (page, uuid) => {
	return axios({
		method: 'GET',
		url: '/api/area-of-listings',
		params: {
			page,
			uuid
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getSubAreas = () => {
	return axios({
		method: 'GET',
		url: '/api/subarea-of-listings',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchListing = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const getSAFromArea = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search/subarea',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchActiveListing = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-active',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchForeclosureListing = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-foreclosure',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchActiveListingBelowAssessment = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-active-below-assessment',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchPriceDropListing = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-price-drop',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const searchActiveDeals = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-active-deals',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const searchForeclosureDeals = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/search-foreclosure-deals',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const searchMyCollection = () => {
	return axios({
		method: 'GET',
		url: '/api/user/collection',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const saveSelection = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/collection',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings: []}
 * @returns // {urls: []}
 */
export const getRoutesForSelection = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/listing/shortest-routes',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: false
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const saveFilterSelection = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/collection/filter',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const saveClusterSelection = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/collection-cluster',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const deleteFromCollection = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/collection',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const deleteFromTagCollection = (formData, tagId) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/collection/tag',
		data: {
			formData,
			tagId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {listings:[]}
 * @returns
 */
export const deleteFromOrganizationCollection = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/organization',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

// export const PostAnnotationToCollection = (formData) => {
//    return axios({
//       method:'UPDATE',
//       url:'/api/user/collection',
//       data:formData,
//       headers: {
//          Accept: "application/json",
//          "Content-Type": "application/json",
//          "Access-Control-Allow-Credentials": true
//       },
//       withCredentials: true
//   })
// }

export const searchOrganizationCollection = () => {
	return axios({
		method: 'GET',
		url: '/api/user/organization/collection',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getListing = (ml) => {
	return axios({
		method: 'GET',
		url: '/api/user/listing/' + ml,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getListingPropertyReport = (ml) => {
	return axios({
		method: 'GET',
		url: '/api/user/propertyreport/' + ml,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getListingRank = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/listing-rank/' + homeId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const updateContactAttribute = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/update-attributes/',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getJSON = (url) => {
	return axios({
		method: 'GET',
		url: url,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		}
	});
};

export const getPropertyOwner = (address) => {
	return axios({
		method: 'GET',
		url: '/api/user/address/' + address,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getAllHomes = () => {
	return axios({
		method: 'GET',
		url: '/api/user/home/',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const searchOnHomes = (value, includeGeospatial) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/search/' + value + '?includeGeospatial=' + includeGeospatial,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const searchOnRecentListings = (value) => {
	return axios({
		method: 'GET',
		url: '/api/user/listing/search/' + value,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {form:string}
 * @returns
 */
export const updatePropertyOwner = (formData) => {
	return axios({
		method: 'PUT',
		url: '/api/user/address/',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId: homeId, comment:string}
 * @returns
 */
export const postComment = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/home/comment',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int} id
 * @param {object} formData {comment:string}
 * @returns
 */
export const updateComment = (id, formData) => {
	return axios({
		method: 'PUT',
		url: '/api/user/comment/' + id,
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const deleteComment = (id) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/comment/' + id,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getCommentsByUser = () => {
	return axios({
		method: 'GET',
		url: '/api/user/comment',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string} mlNum
 * @returns {promise}
 */
export const getHomeCommentsByOrganization = (homeId, limit = null) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/comment/' + homeId,
		params: {
			limit
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {tags: []}
 * @returns {promise}
 */
export const postTags = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/organization/tag',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int} id
 * @param {object} formData {tag:string}
 * @returns
 */
export const updateTag = (id, formData) => {
	return axios({
		method: 'PUT',
		url: '/api/user/organization/tag/' + id,
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {tags: []}
 * @returns {promise}
 */
export const deleteTags = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/organization/tag',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getTagsByOrganization = () => {
	return axios({
		method: 'GET',
		url: '/api/user/organization/tag',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId, tags: []}
 * @returns {promise}
 */
export const postTagToHome = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/home/tag',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId, tagId:int}
 * @returns {promise}
 */
export const deleteTagFromHome = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/home/tag',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getHomeTagsByOrganization = (homeId) => {
	return axios({
		method: 'GET',
		url: `/api/user/home/${homeId}/tag`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getListingsByTag = (tagId, userId) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/tag/' + tagId + '?userId=' + userId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getFavouritesByUserId = (userId) => {
	return axios({
		method: 'GET',
		url: '/api/user/favourites/' + userId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getHomeTags = () => {
	return axios({
		method: 'GET',
		url: '/api/user/organization/tag/all',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {filters:{key:value...}}
 * @returns
 */
export const getListingsByAllTags = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/home/tag/search',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getTagsPopularityByOrganization = () => {
	return axios({
		method: 'GET',
		url: '/api/user/organization/tag/count',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getDetailsPopularityByUserFavourites = () => {
	return axios({
		method: 'GET',
		url: '/api/user/favourites/count',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getListingsBymlNum = () => {
	return axios({
		method: 'GET',
		url: '/api/user/listing/<mlNum>',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {statusArr:[]}
 * @returns {promise}
 */
export const postStatus = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/organization/status',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int} id
 * @param {object} formData {status:string}
 * @returns
 */
export const updateStatus = (id, formData) => {
	return axios({
		method: 'PUT',
		url: '/api/user/organization/status/' + id,
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {statusArr:[]}
 * @returns {promise}
 */
export const deleteStatus = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/organization/status',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getStatusByOrganization = () => {
	return axios({
		method: 'GET',
		url: '/api/user/organization/status',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getHomeStatusByOrganization = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/status/' + homeId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId, statusId: int}
 * @returns {promise}
 */
export const postStatusToHome = (formData) => {
	console.log(formData);
	return axios({
		method: 'POST',
		url: '/api/user/home/status',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getListingsByAddress = (address, city) => {
	return axios({
		method: 'GET',
		url: '/api/listings',
		params: {
			address,
			city
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getRecentListingLocationByAddress = (address, city) => {
	return axios({
		method: 'GET',
		url: '/api/recent_listing/location',
		params: {
			address,
			city
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getAllListings = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/listings/all',
		params: {
			homeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getPdfOfCustomListing = (
	mlNums,
	username,
	generateMethod,
	letterhead,
	printWithUsername
) => {
	let mlNumArr = [];
	mlNumArr.push(mlNums);
	return axios({
		method: 'GET',
		url: `/api/listings/pdf-custom`,
		responseType: 'blob',
		params: {
			username: username,
			generateMethod: generateMethod,
			letterhead: letterhead,
			printWithUsername: printWithUsername,
			mlNums: JSON.stringify(mlNumArr)
		},
		headers: {
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getGenerateYourOwnPdf = (
	mlNums,
	username,
	intro1,
	home1,
	neighbourhood1,
	closing1,
	intro2,
	home2,
	neighbourhood2,
	closing2,
	intro3,
	home3,
	neighbourhood3,
	closing3
) => {
	let mlNumArr = [];
	mlNumArr.push(mlNums);
	return axios({
		method: 'GET',
		url: '/api/listings/generate-your-own-pdf',
		responseType: 'blob',
		params: {
			username: username,
			generateMethod: 'Generate PDF',
			mlNums: JSON.stringify(mlNumArr),
			intro: intro1,
			home: home1,
			neighbourhood: neighbourhood1,
			closing: closing1,
			intro2: intro2,
			home2: home2,
			neighbourhood2: neighbourhood2,
			closing2: closing2,
			intro3: intro3,
			home3: home3,
			neighbourhood3: neighbourhood3,
			closing3: closing3
		},
		headers: {
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getPdfOfCustomMultipleListing = (
	mlNums,
	username,
	generateMethod,
	letterhead,
	printWithUsername
) => {
	return axios({
		method: 'GET',
		url: `/api/listings/pdf-custom`,
		responseType: 'blob',
		params: {
			username: username,
			generateMethod: generateMethod,
			letterhead: letterhead,
			printWithUsername: printWithUsername,
			mlNums: JSON.stringify(mlNums)
		},
		headers: {
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getPdfOffmarket = (mlNum, location) => {
	return axios({
		method: 'GET',
		url: `/api/offmarket-listings-nearby/${mlNum}/letter`,
		responseType: 'blob',
		params: {
			location: location
		},
		headers: {
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId: homeid, listingAddress: address, time: unixTime (int)}
 * @returns {promise}
 */
export const postFollowUpDateToHome = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/home/follow-up',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {homeId: homeid, mlNum: mlNum, homeAddress: address,
 * @returns {promise}
 */
export const clearFollowUpDateToHome = (formData) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/home/remove-follow-up',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string} homeId
 * @returns {promise}
 */
export const getFollowUpDate = (homeId) => {
	return axios({
		method: 'GET',
		url: `/api/user/home/follow-up/${homeId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getAllUserFollowUps = (userRole) => {
	return axios({
		method: 'GET',
		url: `/api/user/all-user-follow-ups`,
		params: {
			userRole
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getAllTaskStatusForUser = (userId, startDate, endDate) => {
	return axios({
		method: 'GET',
		url: `/api/tasks/all-task-status-for-user`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		params: {
			userId,
			startDate,
			endDate
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getAssignedTasksCount = (userId, orgId, startDate, endDate) => {
	return axios({
		method: 'GET',
		url: `/api/tasks/assigned-task-count`,
		params: {
			userId,
			orgId,
			startDate,
			endDate
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getUpcomingUserFollowUps = (userId, userRole, userOrg) => {
	return axios({
		method: 'GET',
		url: `/api/user/upcoming-user-follow-ups`,
		params: {
			userId,
			userRole,
			userOrg
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {csv: file}
 * @param {object} source
 * @param setProgress
 * @returns {promise}
 */
export const uploadClusterFile = (formData, source, setProgress) => {
	return axios({
		method: 'POST',
		url: '/api/parse-clusters',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Credentials': true
		},
		onUploadProgress: (progress) => {
			const value = (progress.loaded / progress.total) * 100;
			setProgress(value);
		},
		withCredentials: true,
		data: formData,
		cancelToken: source
	});
};

// optimize routes for user's assigned tasks
// /**
//  *
//  * @param {string} uuid
//  * @returns {promise}
//  */
// export const optimizeRoutes = (uuid) => {
// 	return axios({
// 		method: 'GET',
// 		url: '/api/optimize-routes',
// 		params: { uuid },
// 		headers: {
// 			Accept: 'application/json',
// 			'Content-Type': 'application/json',
// 			'Access-Control-Allow-Credentials': true
// 		},
// 		withCredentials: true
// 	});
// };

// optimize routes for user's selected listings from search reults page
/**
 *
 * @param {string} mlNums
 * @returns {promise}
 */
export const optimizeRoutes = (mlNums) => {
	return axios({
		method: 'GET',
		url: '/api/optimize-routes',
		params: {
			mlNums: JSON.stringify(mlNums)
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 * @param {string} routeName
 * @param {string} date
 * @param {int} time
 * @returns {promise}
 */
export const editRoute = (routeId, routeName, date, time) => {
	return axios({
		method: 'POST',
		url: '/api/edit-route',
		data: {
			routeId: routeId,
			routeName: routeName,
			date: date,
			time: time
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 * @param {string} routeName
 * @param {string} numItems
 * @param {string} clusterURL
 * @param {string} mlNums
 * @param {string} waypoints
 * @param {string} date
 * @param {int} time
 * @returns {promise}
 */
export const saveRoute = (
	routeId,
	routeName,
	numItems,
	clusterURL,
	mlNums,
	waypoints,
	date,
	time,
	routeReordered
) => {
	let test = {
		routeId: routeId,
		routeName: routeName,
		numItems: numItems,
		clusterURL: clusterURL,
		mlNums: JSON.stringify(mlNums),
		waypoints: JSON.stringify(waypoints),
		date: date,
		time: time,
		routeReordered: routeReordered
	};
	console.log('in POST save route', test);
	return axios({
		method: 'POST',
		url: '/api/save-route',
		data: {
			routeId: routeId,
			routeName: routeName,
			numItems: numItems,
			clusterURL: clusterURL,
			mlNums: JSON.stringify(mlNums),
			waypoints: JSON.stringify(waypoints),
			date: date,
			time: time,
			routeReordered: routeReordered
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 * @returns {promise}
 */
export const getSavedRoutes = () => {
	return axios({
		method: 'GET',
		url: '/api/saved-routes',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 * @returns {promise}
 */
export const getSavedRoute = (routeId) => {
	return axios({
		method: 'GET',
		url: '/api/saved-route',
		params: {
			routeId: routeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getSavedRouteDetails = (routeId) => {
	return axios({
		method: 'GET',
		url: '/api/saved-route-details',
		params: {
			routeId: routeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: false
	});
};

/**
 * @returns {promise}
 */
export const deleteSavedRoute = (routeId) => {
	return axios({
		method: 'DELETE',
		url: '/api/delete-saved-routes',
		params: {
			routeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData {csv: file}
 * @param {object} source
 * @param setProgress
 * @returns {promise}
 */
export const uploadRegionFile = (formData, source, setProgress) => {
	return axios({
		method: 'POST',
		url: '/api/parse-property-regions',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Credentials': true
		},
		onUploadProgress: (progress) => {
			const value = (progress.loaded / progress.total) * 100;
			setProgress(value);
		},
		withCredentials: true,
		data: formData,
		cancelToken: source
	});
};

/**
 * @param {string} area
 * @param {string} subarea
 * @param {string} homeId
 * @returns {promise}
 */
export const getNearbyAgents = (area, subarea, homeId) => {
	return axios({
		method: 'GET',
		url: '/api/agents-nearby',
		params: {
			area,
			subarea,
			homeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int} homeId
 * @returns {promise}
 */
export const getCallRecordings = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/call-recordings',
		params: {
			homeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string}
 * @returns {promise}
 */
export const getAllNearbyActiveListings = (location, dwellingClassification) => {
	return axios({
		method: 'GET',
		url: '/api/active-listings-nearby',
		params: {
			location,
			dwellingClassification
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string}
 * @returns {promise}
 */
export const getAllNearbySoldListings = (location, dwellingClassification) => {
	return axios({
		method: 'GET',
		url: '/api/sold-listings-nearby',
		params: {
			location,
			dwellingClassification
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string}
 * @returns {promise}
 */
export const getAllNearbyOffMarketListings = (location, dwellingClassification) => {
	return axios({
		method: 'GET',
		url: '/api/offmarket-listings-nearby',
		params: {
			location,
			dwellingClassification
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getUserActivities = (limit = null, offset = null, userId = null) => {
	return axios({
		method: 'GET',
		url: '/api/user/activity',
		params: {
			limit,
			offset,
			userId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getUserActivitiesSingleListing = (
	homeId,
	limit = null,
	offset = null,
	userId = null
) => {
	return axios({
		method: 'GET',
		url: `/api/user/activity/${homeId}`,
		params: {
			limit,
			offset,
			userId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getKnockingStatusAndFollowUp = (homeId, address, city) => {
	return axios({
		method: 'GET',
		url: `/api/user/listing/${homeId}/${address}/${city}/knocking-status-and-follow-up`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int}
 * @returns {promise}
 */
export const getRolePermissions = (roleId) => {
	return axios({
		method: 'GET',
		url: `/api/organization/role/${roleId}/permission`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getCommentsAndTags = (HomeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/listing/' + HomeId + '/comments-and-tags',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getMLNumberByHomeId = (HomeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/' + HomeId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getMLNumberByHomeIdPropertyReport = (HomeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/home/propertyreport/' + HomeId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object}
 * @returns {promise}
 */
export const getAssignedTask = (HomeId) => {
	return axios({
		method: 'GET',
		url: '/api/user/listing/' + HomeId + '/assigned-task',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 * @returns {promise}
 */
export const submitPropertyCustomData = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/home/custom-data',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getOrganizationTaskData = () => {
	return axios({
		method: 'GET',
		url: '/api/user/tasks',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getUserTaskBoard = (uuid, statusId = null) => {
	return axios({
		method: 'GET',
		url: `/api/tasks/${uuid}`,
		params: {
			statusId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getUserAssignedTasks = (uuid, startDate, endDate) => {
	return axios({
		method: 'GET',
		url: `/api/tasks/assigned/`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		params: {
			uuid,
			startDate,
			endDate
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getAllUsersAssignedToTask = () => {
	return axios({
		method: 'GET',
		url: `/api/tasks/assigned/all`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getTotalTaskCountPerUser = (startDate, endDate) => {
	return axios({
		method: 'GET',
		url: `/api/tasks/rankings`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		params: {
			startDate,
			endDate
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 * @returns {promise}
 */
export const updateStatusColOrder = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/status-column-order',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 * @returns {promise}
 */
export const updatePropertyAssignments = (formData) => {
	return axios({
		method: 'PUT',
		url: '/api/organization/assignments',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 *	@returns {promise}
 */
export const deleteTask = (formData) => {
	return axios({
		method: 'DELETE',
		url: `/api/tasks`,
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 *	@returns {promise}
 */
export const putTaskOwner = (formData) => {
	return axios({
		method: 'PUT',
		url: '/api/tasks/owner',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 *	@returns {promise}
 */
export const postTaskOwner = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/tasks/owner',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 *	@returns {promise}
 */
export const putTaskCoOwner = (formData) => {
	return axios({
		method: 'PUT',
		url: '/api/tasks/coowner',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getActiveAgents = () => {
	return axios({
		method: 'GET',
		url: '/api/organization/agents',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *	@returns {promise}
 */
export const getOrganizationAssignedStatusId = () => {
	return axios({
		method: 'GET',
		url: '/api/organization/assigned-status',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 * @param {string}
 * @returns {promise}
 */
export const deleteOrganizationRole = (roleId) => {
	return axios({
		method: 'DELETE',
		url: `/api/organization/role/${roleId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getPermissionsPreset = () => {
	return axios({
		method: 'GET',
		url: `/api/permissions`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int}
 * @returns {promise}
 */
export const checkUserHasPermissionId = (permissionId, uuid) => {
	return axios({
		method: 'GET',
		url: `/api/user/permission/${permissionId}/${uuid}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} formData
 * @returns {promise}
 */
export const putRole = (formData) => {
	return axios({
		method: 'PUT',
		url: '/api/organization/role',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getOrganizationRoles = () => {
	return axios({
		method: 'GET',
		url: `/api/organization/role`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string}
 * @returns {promise}
 */
export const getUserRoles = (email) => {
	return axios({
		method: 'GET',
		url: `/api/user/role/${email}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string, object}
 * @returns {promise}
 */
export const putUserRoles = (email, formData) => {
	return axios({
		method: 'PUT',
		url: `/api/organization/user-role/${email}`,
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @returns {promise}
 */
export const getOrganizationUsers = () => {
	return axios({
		method: 'GET',
		url: `/api/organization/user`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const getUserDashboardLayout = (userId) => {
	return axios({
		method: 'GET',
		url: '/api/dashboard/get-layout',
		params: {
			userId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {layout}
 */
export const saveUserDashboardLayout = (userId, layout, widgets) => {
	return axios({
		method: 'POST',
		url: '/api/dashboard/save-layout',
		data: {
			userId,
			layout,
			widgets
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {history}
 */
export const saveSearchHistoryAPI = (history) => {
	return axios({
		method: 'POST',
		url: '/api/user/search/history',
		data: {
			history
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const getSearchHistory = () => {
	return axios({
		method: 'GET',
		url: '/api/user/search/history',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {index}
 */
export const deleteSearchHistoryByIndex = (index) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/search/history',
		data: {
			index
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {history}
 */
export const saveToMyBookmarks = (history) => {
	return axios({
		method: 'POST',
		url: '/api/user/search/bookmarks',
		data: {
			history
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const getMyBookmarks = () => {
	return axios({
		method: 'GET',
		url: '/api/user/search/bookmarks',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {history}
 */
export const updateMyBookmarks = (history) => {
	return axios({
		method: 'PUT',
		url: '/api/user/search/bookmarks',
		data: {
			history
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const deleteMyBookmarksByIndex = (index) => {
	return axios({
		method: 'DELETE',
		url: '/api/user/search/bookmarks',
		data: {
			index
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const getUserNotificationPreferences = () => {
	return axios({
		method: 'GET',
		url: '/api/user/get-notification-preferences',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const createUserNotificationPreferences = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/create-notification-preferences',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const changeUserNotificationPreference = (formData) => {
	return axios({
		method: 'POST',
		url: '/api/user/change-notification-preference',
		data: formData,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const turnOffAllNotifications = () => {
	return axios({
		method: 'POST',
		url: '/api/user/turn-off-all-notifications',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const getAllUserNotifications = () => {
	return axios({
		method: 'GET',
		url: '/api/user/get-all-user-notifications',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const markNotificationAsRead = (notificationId) => {
	return axios({
		method: 'POST',
		url: '/api/user/mark-notification-read',
		data: notificationId,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 *
 */
export const storeClientFCMToken = (token) => {
	return axios({
		method: 'POST',
		url: '/api/user/store-client-fcm-token',
		data: token,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const sendToFollowUpBoss = (property) => {
	return axios({
		method: 'POST',
		url: '/api/fub/post-to-events',
		data: property,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} homeId
 * @returns
 */
export const getEstimations = (HomeId) => {
	return axios({
		method: 'GET',
		url: `/api/estimation/${HomeId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {string}
 * @returns {promise}
 */
export const getClosestPointsOfInterest = (location) => {
	return axios({
		method: 'GET',
		url: '/api/closest-points-of-interest',
		params: {
			location
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getOwnerDetailsInfomation = (homeId, MLNum) => {
	return axios({
		method: 'GET',
		url: `/api/owner-additional-info`,
		params: {
			homeId,
			MLNum
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getAiLetter = (homeId, username, generateMethod, letterhead, printWithUsername) => {
	return axios({
		method: 'GET',
		url: `/api/listings/ai-letter`,
		responseType: 'blob',
		params: {
			username: username,
			homeId: homeId,
			generateMethod: generateMethod,
			letterhead: letterhead,
			printWithUsername: printWithUsername
		},
		headers: {
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {object} homeId
 * @returns
 */
export const getAssessmentData = (homeId) => {
	return axios({
		method: 'GET',
		url: `/api/assessment/${homeId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const checkRenovated = (homeId) => {
	return axios({
		method: 'GET',
		url: `/api/check-renovated/${homeId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};
export const createCheckoutSession = (amount) => {
	return axios({
		method: 'POST',
		url: '/api/payments/create-checkout-session',
		data: {
			amount
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const updateUserPayment = (sessionId) => {
	return axios({
		method: 'POST',
		url: `/api/payments/update-user-payment/${sessionId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getUserBalance = (userId) => {
	return axios({
		method: 'GET',
		url: `/api/payments/get-user-balance/${userId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const updateUserBalance = (userId, amount) => {
	return axios({
		method: 'POST',
		url: `/api/payments/update-user-balance`,
		data: {
			userId,
			amount
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const createNewOrderConnection = (order) => {
	return axios({
		method: 'POST',
		url: `/api/order-connection`,
		data: order,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const checkListingHasOrderConnection = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/check-order-connection',
		params: {
			homeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const getChipsForListing = (homeId) => {
	return axios({
		method: 'GET',
		url: '/api/listings/chips',
		params: {
			homeId
		},
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const sendDataToBackend = (data) => {
	return axios({
		method: 'POST',
		url: '/api/lead/capture',
		data: data,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

export const fetchUserDetails = (orgId, userId) => {
	return axios({
		method: 'GET',
		url: `/api/user/details?organizationId=${orgId}&userId=${userId}`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};

/**
 *
 * @param {int}
 * @returns {promise}
 */
export const getAllPermissionForUser = (userId) => {
	return axios({
		method: 'GET',
		url: `/api/user/${userId}/all-permissions`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': true
		},
		withCredentials: true
	});
};
