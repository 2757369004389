import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../SaigeLogo.svg';

import { loginUser } from '../../../api/serverRequests';
import { useUserState } from '../../../hook/customerHook';
import useAnalyticsEventTracker from '../../../hook/analyticsHook';
import { Copyright } from '../../';

import moment from 'moment';
import { green, lightGreen } from '@material-ui/core/colors';
import { BorderColor } from '@material-ui/icons';
import Image from '../../../assets/images/Login_Page_Image.png';
import { saveUserLocation } from '../../../helper/parser';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${Image})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		padding: theme.spacing(15, 10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		backgroundColor: theme.palette.grey[0]
	},
	signUp: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},

	formControl: {
		marginTop: theme.spacing(1),
		width: '100%',
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		alignItems: 'center'
	},
	submit: {
		margin: theme.spacing(0, 0, 2),
		backgroundColor: '#004F2B',
		boxShadow: 'none'
	},
	linkContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	forgotPass: {
		marginLeft: 'auto',
		marginRight: '0',
		color: '#004F2B',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2)
	},
	signUpText: {
		color: 'black'
	},
	signUpLink: {
		color: 'blue'
	},
	textInput: {
		'& label.Mui-focused': {
			color: '#004F2B',
			background: 'rgba(72, 193, 198, 0.06)'
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: '#004F2B'
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#004F2B'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},

	copyRight: {
		alignSelf: 'end',
		color: 'blue'
	},
	logoImg: {
		height: '80px',
		width: '135px',
		marginBottom: theme.spacing(2)
	},
	title: {
		position: 'absolute',
		[theme.breakpoints.down(960)]: {
			display: 'none'
		},
		color: '#FF9900',
		marginTop: theme.spacing(6),
		marginLeft: theme.spacing(7),
		textTransform: 'uppercase',
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: '28px',
		lineHeight: '110%',
		width: '350px',
		height: '84px',
		transform: 'scale(1.05, 1)'
	},
	subTitle: {
		position: 'absolute',
		[theme.breakpoints.down(960)]: {
			display: 'none'
		},
		width: '350px',
		height: '84px',
		marginTop: theme.spacing(14.25),
		marginLeft: theme.spacing(7),
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '13px',
		lineHeight: '125%',
		color: '#1D2024',
		transform: 'scale(1.05, 1)'
	},
	accountCreated: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background:
			'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32',
		width: '410px',
		height: '48px',
		allign: 'center'
	},
	accountCreatedText: {
		marginTop: theme.spacing(1.5),
		allign: 'center',
		fontStyle: 'normal',
		fontWeight: '400'
	}
}));

export default function Login(props) {
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit, control, errors, watch } = methods;
	const [state, setState] = React.useState({
		checked: true
	});
	const location = useLocation();

	const { email, password } = watch(['email', 'password']);

	const isEmailFilled = email && email.trim() !== '';
	const isPasswordFilled = password && password.trim() !== '';
	const isFormFilled = isEmailFilled && isPasswordFilled;

	const [userState, dispatchUser] = useUserState();
	const [errMessage, setErrMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showAccountCreated, setShowAccountCreated] = useState(false);

	const gaEventTracker = useAnalyticsEventTracker('Login');

	const classes = useStyles();

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async (formData) => {
		loginUser(formData)
			.then((res) => {
				if (res.data.success) {
					const user = res.data.user;
					gaEventTracker('User logged in', user.user_id);
					dispatchUser({
						isLoggedIn: true,
						user: user,
						lastActivity: moment().format(),
						Remember: formData.remember,
						advFilters: { dwellingTypes: 'detached', mlsSearch: true }
					});
					if (location.state?.from) {
						history.push(location.state.from, { from: '/login' });
					} else {
						history.push('/user/search', { from: '/login' });
					}
				} else {
					setErrMessage(res.data.err);
				}
			})
			.catch((err) => {
				setErrMessage(err.message);
				console.log(err.message);
			});
	};

	// This function allows to ask for user's permission to provide their location"
	// useEffect(() => {
	// 	saveUserLocation();
	// }, []);
	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={2} md={6} className={classes.image} />
			<Typography variant="h3" className={classes.title}>
				The #1 property discovery platform
			</Typography>
			<Typography variant="p" className={classes.subTitle}>
				Identify off-market properties before they hit the market with our advanced
				AI-powered application.
			</Typography>

			<Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					{props.location.state === false && (
						<div className={classes.accountCreated}>
							<Typography className={classes.accountCreatedText} variant="p">
								Account Created
							</Typography>
						</div>
					)}

					<img src={logo} alt="Logo" className={classes.logoImg} />

					<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
						{errMessage && <p className="text-danger my-0">{errMessage}</p>}
						<Controller
							name="email"
							defaultValue=""
							control={control}
							render={(props) => (
								<TextField
									className={classes.textInput}
									variant="outlined"
									margin="normal"
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									autoFocus
									value={props.value}
									onChange={props.onChange}
									inputRef={props.ref}
								/>
							)}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: 'Invalid email address'
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>

						<Controller
							name="password"
							defaultValue=""
							control={control}
							render={(props) => (
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel htmlFor="password">Password</InputLabel>
									<OutlinedInput
										className={classes.textInput}
										name="password"
										id="password"
										label="Password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="current-password"
										value={props.value}
										onChange={props.onChange}
										inputRef={props.ref}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													edge="end"
												>
													{showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							)}
							rules={{
								required: 'Password is required',
								minLength: {
									value: 8,
									message: 'Minimum length of password is 8 characters!'
								},
								pattern: {
									value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,)(:";'\+_=]).{8,}$/,
									message:
										"A password must contain at least eight characters, including at least one number, both lower and uppercase letters, and special characters of '#?!@$%^&*-'"
								}
							}}
						/>
						<ErrorMessage
							errors={errors}
							name="password"
							render={({ message }) => <p className="text-danger mx-2">{message}</p>}
						/>
						<div className={classes.linkContent}>
							<Link
								href="/reset-password"
								variant="body2"
								className={classes.forgotPass}
							>
								Forgot password?
							</Link>
							<FormControlLabel
								control={
									<Controller
										name="remember"
										defaultValue={true}
										control={control}
										render={(props) => (
											<Checkbox
												label="My checkbox"
												{...props}
												checked={props.value}
												defa
												onChange={(e) => props.onChange(e.target.checked)}
												color=""
											/>
										)}
									/>
								}
								label={
									<Box component="div" color="black" fontSize={14}>
										Remember Me
									</Box>
								}
							/>
						</div>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={!isFormFilled}
						>
							Sign In
						</Button>

						<div className={classes.signUp}>
							<p className={classes.signUpText}>
								Don't have an account?
								<Link
									className={classes.signUpLink}
									href="mailto:info@realsaige.com?subject=RE: App Access"
									variant="body2"
								>
									{' '}
									Sign up now!
								</Link>
							</p>
						</div>

						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
}
