import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
	Grid,
	Paper,
	TextField,
	Button,
	Typography,
	Box,
	Container,
	AppBar,
	Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	fetchUserDetails,
	getListingPropertyReport,
	getMLNumberByHomeIdPropertyReport,
	getAllNearbyActiveListings,
	getAllNearbySoldListings
} from '../../../api/serverRequests';
import DefaultImage from '../../../assets/images/lead_logos/default.png';
import BackgroundImage from '../../../assets/images/background.jpg';
import LeadDetailTab from '../../comps/LeadPropertyDetails/LeadDetails';
import LeadNearbyActiveTab from '../../comps/LeadPropertyDetails/LeadNearbyActive';
import LeadNearbySoldTab from '../../comps/LeadPropertyDetails/LeadNearbySold';
import Map from '../../comps/Map';
import { useUserState } from '../../../hook/customerHook';

const useStyles = makeStyles((theme) => ({
	root: {
		overflowX: 'hidden',
		width: '100%',
		background: `url(${BackgroundImage}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		color: '#fff'
	},
	container: {
		padding: theme.spacing(4),
		margin: '0 auto',
		maxWidth: '1000px',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		borderRadius: '15px'
	},
	paper: {
		padding: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		marginBottom: theme.spacing(3)
	},
	heroImage: {
		width: '100%',
		height: 'auto',
		marginBottom: theme.spacing(4),
		borderRadius: '10px'
	},
	textField: {
		width: '100%',
		marginBottom: theme.spacing(2),
		borderRadius: '10px',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#004F2B'
			},
			'&:hover fieldset': {
				borderColor: '#00391E'
			},
			'&.Mui-focused fieldset': {
				borderColor: '#004F2B'
			}
		}
	},
	formWrapper: {
		marginTop: theme.spacing(2),
		width: '100%'
	},
	title: {
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		textAlign: 'center',
		color: '#333'
	},
	sectionTitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		color: '#555'
	},
	submit: {
		marginTop: theme.spacing(3),
		backgroundColor: '#004F2B',
		width: '200px',
		height: '50px',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		color: '#FFFFFF',
		borderRadius: '25px',
		boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
		transition: 'background-color 0.3s, transform 0.3s',
		'&:hover': {
			backgroundColor: '#00391E',
			transform: 'scale(1.05)'
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: 'auto'
		}
	},
	appBar: {
		backgroundColor: 'transparent',
		boxShadow: 'none'
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		color: '#fff'
	},
	addressField: {
		border: '2px solid #004F2B',
		borderRadius: '15px',
		padding: '10px',
		fontSize: '1.2rem',
		color: '#333',
		textAlign: 'center',
		marginBottom: theme.spacing(3),
		backgroundColor: '#f9f9f9'
	},
	inviteText: {
		color: '#555',
		textAlign: 'center',
		fontSize: '1.1rem',
		marginBottom: theme.spacing(3)
	}
}));

export default function PropertyReport() {
	const classes = useStyles();
	const {
		control,
		setValue,
		formState: { errors }
	} = useForm();
	const [orgLogo, setOrgLogo] = useState(null);
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [userId, setUserId] = useState('');
	const [homeId, setHomeId] = useState('');
	const [orgId, setOrgId] = useState('');
	const [ml, setMlNum] = useState('');
	const [listing, setListing] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [activeListings, setActiveListings] = useState([]);
	const [isLoadingActiveListings, setIsLoadingActiveListings] = useState(true);
	const [isLoadingSoldListings, setIsLoadingSoldListings] = useState(true);
	const [soldListings, setSoldListings] = useState([]);
	const [userState, dispatchUser] = useUserState();
	const isAnyLoading = isLoading || isLoadingActiveListings || isLoadingSoldListings;

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const address = queryParams.get('address');
		const city = queryParams.get('city');
		const userId = queryParams.get('userid');
		const homeId = queryParams.get('homeId');
		const orgId = queryParams.get('orgId');

		if (address) setAddress(address);
		if (city) setCity(city);
		if (userId) setUserId(userId);
		if (homeId) setHomeId(homeId);
		if (orgId) setOrgId(orgId);

		if (homeId) {
			getMLNumberByHomeIdPropertyReport(homeId)
				.then((response) => {
					if (response.data) {
						console.log(response.data);
						setMlNum(response.data.mlnum);
					}
				})
				.catch((error) => {
					console.error('Error fetching mlNum:', error);
				});
		}

		if (orgId || userId) {
			fetchUserDetails(orgId, userId)
				.then((res) => {
					if (res.data.success) {
						const userDetails = res.data.user_details;
						const logoPath = require('../../../assets/images/lead_logos/' +
							userDetails.logo);
						setOrgLogo(logoPath);
					}
				})
				.catch((err) => {
					console.error('Error fetching user details:', err);
				});
		}
	}, [location, setValue]);

	useEffect(() => {
		if (!ml) return;

		const fetchListingData = async () => {
			try {
				const res = await getListingPropertyReport(ml);
				if (res.status === 200) {
					console.log(res.data.listing);
					setListing(res.data.listing);
				}
			} catch (err) {
				console.error('Error fetching listing:', err.message);
			} finally {
				setIsLoading(false);
			}
		};
		fetchListingData();
	}, [ml]);

	useEffect(() => {
		setIsLoadingActiveListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbyActiveListings(listing.location, listing.dwellingClassification)
					.then((res) => {
						if (res.status === 200) setActiveListings(res.data.active_listings);
						if (!unmounted) setIsLoadingActiveListings(false);
					})
					.catch((err) => {
						console.log(err);
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingActiveListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [listing]);

	useEffect(() => {
		setIsLoadingSoldListings(true);
		let unmounted = false;
		const fetchData = async () => {
			if (!unmounted) {
				getAllNearbySoldListings(listing.location, listing.dwellingClassification)
					.then((res) => {
						if (res.status === 200) setSoldListings(res.data.sold_listings);
						if (!unmounted) setIsLoadingSoldListings(false);
					})
					.catch((err) => {
						if (err.response.status === 401) {
							dispatchUser({
								isLoggedIn: false
							});
							return;
						} else if (!unmounted) {
							setIsLoadingSoldListings(false);
						}
						console.error(err.message);
					});
			}
		};
		fetchData();
		return () => {
			unmounted = true;
		};
	}, [listing]);

	return (
		<Box className={classes.root}>
			<AppBar position="static" className={classes.appBar}>
				<Toolbar className={classes.toolbar}></Toolbar>
			</AppBar>
			<Container maxWidth="md">
				<img
					src={orgLogo ? orgLogo : DefaultImage}
					alt="Hero"
					className={classes.heroImage}
				/>
				<Grid container justifyContent="center" className={classes.container}>
					{address && (
						<Paper className={classes.paper} elevation={3}>
							<Typography variant="h5" component="h2" className={classes.title}>
								Complete Home Report for
							</Typography>
							<Typography
								variant="h4"
								component="h2"
								className={classes.addressField}
							>
								{address}, {city}
							</Typography>
						</Paper>
					)}

					{isAnyLoading ? (
						<Typography style={{ color: 'black', fontWeight: 'bold' }}>
							Please wait while we generate your report...
						</Typography>
					) : (
						<>
							{listing && (
								<Paper className={classes.paper} elevation={3}>
									<LeadDetailTab listing={listing} />
								</Paper>
							)}

							<Paper className={classes.paper} elevation={3}>
								<LeadNearbyActiveTab
									listing={listing}
									activeListings={activeListings}
									isLoadingActiveListings={isLoadingActiveListings}
								/>
							</Paper>

							<Paper className={classes.paper} elevation={3}>
								<LeadNearbySoldTab
									listing={listing}
									soldListings={soldListings}
									isLoadingSoldListings={isLoadingSoldListings}
								/>
							</Paper>
						</>
					)}
				</Grid>
			</Container>
		</Box>
	);
}
